import request from '@/utils/requestV2'
const qs = require('qs')

// 根据条件获取文件详情
export function agreefile (data) {
  return request({
    url: '/ooh-msp/v2/file/agreefile',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取文件对象
export function getfile (data) {
  return request({
    url: '/ooh-msp/v2/file/getfile',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据id删除文件
export function deletefile (data) {
  return request({
    url: '/ooh-msp/v2/file/deletefile',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询（指定时间段）填充画面上传日期
export function getFileUploadDateList (data) {
  return request({
    url: '/ooh-msp/v2/file/getfileuploaddatelist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取文件审批记录
export function allFileApproval (data) {
  return request({
    url: '/ooh-msp/v2/file/allfileapproval',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 根据条件获取文件详情及库存数量
export function agreeFileStat (data) {
  return request({
    url: '/ooh-msp/v2/file/agreefilestat',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 更新文件发布类型
export function updateFileType (data) {
  return request({
    url: '/ooh-msp/v2/file/updatefiletype',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 终止公益画面审批流程
export function terminateProcess (data) {
  return request({
    url: '/ooh-msp/v2/file/terminateProcess',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询素材库
export function getMaterialLibrary (data) {
  return request({
    url: '/ooh-msp/v2/file/getMaterialLibrary',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 修改文件素材库名称
export function modifyMaterialLibrary (data) {
  return request({
    url: '/ooh-msp/v2/file/modifyMaterialLibrary',
    method: 'post',
    data: qs.stringify(data)
  })
}
