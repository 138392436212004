import { getMaterialLibrary } from '@/api/msp/file'

export const taskMixins = {
  data () {
    return {
      materialLibList: [] // 素材库
    }
  },
  methods: {
    /**
     * 获取素材库数据
     * @param {number} materialType 素材类别
     * @returns Array
     */
    async getMaterialLibData (materialType) {
      const res = await getMaterialLibrary({ type: materialType })
      if (res && !res.errcode) {
        this.materialLibList = res
      } else {
        this.materialLibList = []
      }
    }
  }
}
