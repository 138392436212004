<style lang="less" scoped>
.selectedFile {
  border: 2px solid #ff9900;
}
</style>
<template>
  <div>
    <Divider dashed style="color:#fff">设置素材</Divider>
    <div class="p-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">素材上传日期</h4>
      <div class="m-b-10">
        <label>素材类型：</label>
        <RadioGroup v-model="materialType" type="button" size="small" @on-change="onChangeMaterialType">
          <Radio v-for="item in materialTypeArray" :key="'m_' + item.value" :label="item.value">{{ item.name }}</Radio>
        </RadioGroup>
      </div>
      <Row :gutter="8" class="m-b-10">
        <i-col span="12">
          <Select v-model="dateQuery.materialLibrary" size="small" clearable filterable placeholder="选择素材库，可搜索"
            @on-change="getFileDates">
            <Option v-for="item in materialLibList" :key="'mLib_' + item" :value="item">{{ item }}</Option>
          </Select>
        </i-col>
        <i-col span="12">
          <Select v-model="fileDate" size="small" clearable filterable placeholder="选择素材上传日期" @on-change="onChangeFileDate">
            <Option v-for="item in fileUploadDates" :key="'f_' + item" :value="item">{{ item }}</Option>
          </Select>
        </i-col>
      </Row>
      <div class="m-b-10">
        <Input size="small" v-model="query.keyword" search enter-button placeholder="可输入画面名称关键字搜索"
          @on-search="onSearchFile" />
      </div>
      <div class="text-center">
        <Page :total="fileTotal" :current="query.pageNumber" size="small" show-total @on-change="onChangePage" />
      </div>
    </div>
    <div class="p-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">上刊素材（只可单选）</h4>
      <div class="p-b-10">
        <p class="text-orange p-b-10 text-14">提示：</p>
        <p class="text-14">请先选择一张素材，您所选素材需要设置<span class="text-orange">素材上刊名称</span>，才可进⾏下⼀步，设置<span
            class="text-orange">素材上刊名称</span>以保证运维<span class="text-orange">准确上刊</span></p>

      </div>
      <div class="p-b-10" v-if="setFileIdArray.length">
        <label>已设置素材编号：{{ setFileIdArray.toString() }}</label>
      </div>
      <Row :gutter="8">
        <template v-if="fileArray.length > 0">
          <i-col span="8" class="p-b-10" v-for="file in fileArray" :key="'file_' + file.id">
            <a href="javascript:;" @click="handleChoiceFile(file)">
              <img v-if="file.mimeType.includes('image')" :src="formatImageUrl(file.fileKey, 200, 0)"
                :class="currSelectedFile && currSelectedFile.id === file.id ? 'selectedFile' : ''"
                style="width:100%;height:80px">
              <Card v-if="file.mimeType.includes('video') || file.mimeType.includes('audio')"
                :class="currSelectedFile && currSelectedFile.id === file.id ? 'selectedFile' : ''"
                style="word-break: break-all;word-wrap: break-word;height:80px;width:100%;">
                {{ file.fileName }} (<span class="text-orange">{{ file.duration }}</span>秒)
              </Card>
            </a>
            <div>
              <Label>编号：{{ file.id }}</Label>
              <Input size="small" style="width:100%" v-model="file.realFileName" placeholder="文件名称" />
            </div>
          </i-col>
        </template>
      </Row>
    </div>
    <div class="p-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">资源序号选择</h4>
      <div class="p-b-10">
        <p class="text-orange text-14">提示：</p>
        <p class="text-14">如您是批量选择设置，此处只会加载资源中最⼤编号数， 设置的素材将对应到所有资源的对应编号上</p>
      </div>
      <div v-if="currSelectedFile && currSelectedFile.id">
        <div class="m-b-5">
          <Button type="primary" size="small" @click="handelSelectAll">全选</Button>
        </div>

        <CheckboxGroup v-model="selectedStairIds" @on-change="handleChangeStair">
          <Checkbox style="width:70px;margin-right:5px;margin-bottom:5px;" border v-for="(child, index) in stairIdArray"
            :label="child.serial" :key="'serial_' + index" :disabled="child.disabled">{{ child.serial }}</Checkbox>
        </CheckboxGroup>
      </div>
    </div>
    <div class="text-right">
      <Button type="success" size="small" @click="handleSubmit" :disabled="btnDisabled">确认设置</Button>
    </div>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { setScreenMixins } from '@/assets/mixins/setScreen'
import { agreeFileStat, getFileUploadDateList } from '@/api/msp/file'
import { updateTaskitemFile } from '@/api/msp/demandV3'
import { getFillType } from '@/api/msp/status'
import { taskMixins } from '@/components/demand/mixins/index'
export default {
  mixins: [sysMixins, setScreenMixins, taskMixins],
  data () {
    return {
      materialType: 0,
      materialTypeArray: [
        { value: 0, name: '全部' }
      ],
      fileUploadDates: [],
      fileDate: '',
      fileArray: [],
      dateQuery: {
        type: null,
        materialLibrary: '', // 素材库绑定值
        startDate: '',
        endDate: ''
      },
      query: {
        pageNumber: 1,
        pageSize: 15,
        approvalDate: '',
        approvalId: null,
        fileTypes: 1,
        keyword: null,
        status: 5,
        createDate: '',
        type: null,
        materialLibrary: '' // 素材库绑定值
      },
      fileTotal: 0,
      btnDisabled: true
    }
  },
  computed: {
    taskInfo () {
      return this.$store.state.supplierWorkOrder.curTaskInfo
    },
    selectedResourceIds () {
      return [this.$store.state.supplierWorkOrder.curTaskInfo.deviceId]
    },
    selectedTaskitemIds () {
      return [this.$store.state.supplierWorkOrder.curTaskInfo.id]
    },
    fileCreateDate () {
      return this.fileDate
    }
  },
  created () {
    this.getScreenTypeData()
    this.getFileDates()
    this.getMaterialLibData()
  },
  methods: {
    getScreenTypeData () {
      getFillType().then(res => {
        if (res && !res.errcode) {
          this.materialTypeArray = this.materialTypeArray.concat(res)
        }
      })
    },
    onChangeMaterialType () {
      const type = this.materialType === 0 ? undefined : this.materialType
      this.dateQuery.materialLibrary = ''
      this.fileDate = ''
      this.fileTotal = 0
      this.query.pageNumber = 1
      this.getMaterialLibData(type)
      this.getFileDates()
    },
    /**
    * 获取素材上传时间集合
    */
    getFileDates () {
      this.dateQuery.type = this.materialType === 0 ? null : this.materialType
      this.fileDate = ''
      this.fileUploadDates = []
      getFileUploadDateList(this.dateQuery).then(res => {
        if (res && !res.errcode) {
          this.fileUploadDates = res
          // this.fileDate = this.fileDateArray[0]
          this.onSearchFile()
        }
      })
    },
    onChangeFileDate () {
      this.onSearchFile()
    },
    /**
     * 获取已通过审核的画面文件
     */
    getFileData () {
      this.fileArray = []
      this.fileTotal = 0
      this.query.type = this.materialType === 0 ? null : this.materialType
      this.query.createDate = this.fileDate || ''
      this.query.materialLibrary = this.dateQuery.materialLibrary
      agreeFileStat(this.query).then(res => {
        if (res && !res.errcode) {
          this.fileArray = res.fileVOList.list
          this.fileTotal = res.fileVOList.totalRow
          this.query.pageNumber = res.fileVOList.pageNumber
        } else {
          this.fileTotal = 0
          this.query.pageNumber = 1
        }
      })
    },
    onSearchFile () {
      this.query.pageNumber = 1
      this.getFileData()
    },
    onChangePage (curPage) {
      this.query.pageNumber = curPage
      this.getFileData()
    },
    handleSubmit () {
      let fileKeys = ''
      if (this.selectedFileArray && this.selectedFileArray.length) {
        // 获取已设置序号画面
        var t = []
        this.selectedFileArray.forEach(item => {
          if (item.serials.length && !t.some(s => s.fileId === item.fileId)) {
            item.fileName = this.fileArray.find(x => x.id === item.fileId).realFileName
            t.push(item)
          }
        })
        var setTotal = t.length === 0 ? 0 : t.reduce((prev, cur) => {
          return (typeof (prev) === 'number' ? prev : prev.serials.length) + cur.serials.length
        })

        if (setTotal < this.stairIdArray.length) {
          this.$Notice.warning({ desc: '当前存在未设置序号的项' })
          return false
        }
        fileKeys = t.map(x => {
          return {
            fileKey: x.filePath,
            mimeType: x.mimeType,
            fileId: x.fileId,
            fileName: x.fileName,
            quanitty: x.serials.length > 0 ? this.stairIdArray.filter(f => x.serials.some(s => s === f.serial)).map(m => m.number).reduce((prev, cur) => { return prev + cur }) : 0,
            serials: x.serials
          }
        })
      } else {
        this.$Notice.warning({ desc: '请选择需要设置的素材' })
        return false
      }

      const postData = {
        taskId: this.taskInfo.taskId,
        actionDate: this.taskInfo.actionDate,
        remarks: this.taskInfo.remark,
        extras: this.taskInfo.extra,
        fileKeys: fileKeys && fileKeys.length ? JSON.stringify(fileKeys) : '',
        picturesource: this.taskInfo.picturesource,
        taskitemIds: JSON.stringify([this.taskInfo.id])

      }

      // 上刊设置
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误，并提交？',
        onOk: () => {
          updateTaskitemFile(postData).then(res => {
            if (res && res.errcode === 0) {
              // this.$emit('on-change-item', { actionDate: postData.actionDate, domainId: this.taskInfo.domainId, queryType: this.taskInfo.type })
              this.$store.dispatch('getSupplierTaskData')
              this.currSelectedFile = {}
              this.selectedFileArray = []
              this.selectedStairIds = []
              this.$Notice.success({ desc: '操作成功' })
              // this.setLeftComponent('')
              // this.setShowLeftBlock(false)
            }
          })
        }
      })
    }
  }
}
</script>
